input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 12px 16px;
  font-size: 16px;
  color: white;
  background-color: #242526;
  border: 1px solid #3a3b3c;
  border-radius: 25px;
  outline: none;
  transition:
    border-color 0.3s ease,
    padding-top 0.3s ease;
  padding-bottom: 8px;
}

input:focus {
  border-color: #6200ea;
}

.full-width {
  width: -webkit-fill-available;
}
